const MatchHeightHelper = require('./matchheight-update.js');
global.Modernizr = require('modernizr');

(function($) {
  $(document).ready(function() {
    var

      $body = $('body'),
      country = $body.data('country') ? $body.data('country'): 'fr'
    ;

    // Init linkify
    require('linkifyjs/jquery')($, document);
    $('.linkify').linkify();

    /*
     * JQUERY VALIDATE.
     * - Javascript form validation before submit.
     */
    if ('undefined' !== typeof $.fn.validate && $.isFunction($.fn.validate)) {
      /*
       * Configure jquery validate for Bootstrap 3.
       */
      $.validator.setDefaults({
        'highlight': function(element) {
          // Add the .has-error class to the form-group.
          $(element).closest('.form-group').addClass('has-error');
        },
        'unhighlight': function(element) {
          // Remove the .has-error class from the form-group.
          $(element).closest('.form-group').removeClass('has-error');
        },
        'errorElement': 'span',
        'errorClass': 'help-block',
        'errorPlacement': function(error, element) {
          // Handle the error placement for checkbox & radio.
          if (element.parent('.input-group').length || 'checkbox' === element.prop('type')  || 'radio' === element.prop('type')) {
            error.appendTo(element.parent());
          } else {
            error.insertAfter(element);
          }
        }
      });

      // Add a polyfill method to test custom regexp against user input.
      $.validator.addMethod('cemail', function(value, element, regexp)  {
        regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

        return this.optional(element) || regexp.test(value);
      }, 'Please enter a valid email address');

      /*
       * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
       * the same page, eg. sidebar and footer.
       */
      $('.js-contact-form, .js-newsletter-form').each(function(i, e) {
        $(e).validate({
          'rules': {
            'front_message_type[email]': {
              'email': false,
              'cemail': true
            },
            'front_newsletter_type[email]': {
              'email': false,
              'cemail': true
            }
          }
        });
      });
    }

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();

    $('.js-match-height .js-match-height-item').matchHeight();

    $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      MatchHeightHelper.update($container[0], true, '.js-match-height .card');
    });

    $('.js-buckle--assets').on('showing.jq.offie-dropdown', '[data-toggle="offie-dropdown"]', function (event) {
      var buckle = $(event.target).closest('.js-buckle--folder').data('buckle');

      // Fetch content of the folder.
      buckle.fetch({});
    });

    $('.js-buckle--assets').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      // Update the grid.
      MatchHeightHelper.update($container[0], true, '.js-match-height .card');

      // Enable any child buckle component (folders).
      $element.find('[data-toggle="buckle"]').buckle();

      // Enable any offie dropdown component (folders).
      $element.find('[data-toggle="offie-dropdown"]').offieDropdown();
    });

    // Datetimepicker
    var dateTimePickerOptions = {
      'format': 'yyyy-mm-dd',
      'startView': 'year',
      'minView': 'month',
      'fontAwesome': true,
      'autoclose': true
    };

    $('#front_search_type_start').datetimepicker(dateTimePickerOptions);
    $('#front_search_type_end').datetimepicker(dateTimePickerOptions);

    require('./modules/share.js');

    // Didomi Cookie Banner in Footer
    let didomiFooter = document.getElementsByClassName('didomi-footer') ?? '';
    if (didomiFooter.length > 0) {
      didomiFooter[0].addEventListener('click', function(e) {
        e.preventDefault();
        window.Didomi?.preferences.show();
      });
    }
  });
}(jQuery));

